import clsx from 'clsx'
import moment from 'moment'
import {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

// @ts-ignore
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// @ts-ignore:
import Dropzone from 'react-dropzone'

// @ts-ignore:
import Select from 'react-select'

import {useFormik} from 'formik'

import {useIntl} from 'react-intl'
import {KTSVG, selectStyles} from '../../../../_metronic/helpers'
import {swal} from '../../../../_metronic/helpers/swal'

import {swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {DeleteModal} from './DeleteModal'
import axiosInstance from '../../../utils/axiosInstance'

export const ItemModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  let modalRef = useRef(null)
  const deleteModalRef = useRef()

  const validationSchema = Yup.object().shape({
    media_file: Yup.mixed(),
    // .when('type', {
    //   is: 'photo',
    //   then: Yup.mixed().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    // })
    // .when('type', {
    //   is: 'video',
    //   then: Yup.mixed().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    // }),
    language: Yup.string().nullable(),
    region_id: Yup.number().integer().nullable(),
    send_date: Yup.date().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
    system: Yup.string(),
    // text: Yup.string(),
    type: Yup.string().required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
  })

  const initialValues = {
    media_file: '',
    language: '',
    region_id: null,
    send_date: '',
    system: '',
    text: '',
    type: '',
  }

  if (!!props.item && !!props.item.id) {
    initialValues.media_file = props.item.media_file
    initialValues.language = props.item.language
    initialValues.region_id = props.item.region_id
    initialValues.send_date = props.item.send_date
    initialValues.system = props.item.system
    initialValues.text = props.item.text
    initialValues.type = props.item.type
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      let formData: any = new FormData()
      formData.append('language', values.language)
      !!values.region_id && formData.append('region_id', values.region_id)
      formData.append('send_date', moment(values.send_date).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('system', values.system || 'Telegram')
      formData.append('text', values.text)
      formData.append('type', values.type)
      formData.append('media_file', values.media_file)

      try {
        const res = await axiosInstance.put(`/notifications/${props.item.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_UPDATE'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status === 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        setLoading(false)
        return
      }
      setLoading(false)
      setShow(false)
      setSubmitting(false)
      props.refreshData()
    },
  })

  const language: any = [
    {id: '', name: intl.formatMessage({id: 'COMMON.EVERYONE'})},
    {id: 'ru', name: intl.formatMessage({id: 'COMMON.LANG.RU'})},
    {id: 'uz', name: intl.formatMessage({id: 'COMMON.LANG.UZ'})},
    {id: 'en', name: intl.formatMessage({id: 'COMMON.LANG.EN'})},
  ]

  const type: any = [
    {value: 'text', label: intl.formatMessage({id: 'COMMON.TEXT'})},
    {value: 'photo', label: intl.formatMessage({id: 'COMMON.PHOTO'})},
    {value: 'video', label: intl.formatMessage({id: 'COMMON.VIDEO'})},
  ]

  return (
    <>
      <Modal
        className='es-modal'
        enforceFocus={false}
        size='lg'
        show={show}
        onHide={handleClose}
        id='itemInfoModal'
        ref={modalRef}
        centered
      >
        <Modal.Header>
          <h2 className='modal-title'>{intl.formatMessage({id: 'COMMON.CHANGE'})}</h2>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className='row' onSubmit={formik.handleSubmit}>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <div></div>
            )}

            <div className='col-md-4 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.TYPE'})}</label>
              <Select
                // classNamePrefix='react-select'
                styles={selectStyles}
                placeholder=''
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                className='react-select-styled react-select-solid'
                classNames={{
                  control: () =>
                    formik.touched.type && !formik.errors.type
                      ? 'form-select p-0 border-success'
                      : formik.touched.type && formik.errors.type
                      ? 'form-select p-0 border-danger'
                      : 'form-select p-0',
                }}
                options={type}
                onChange={(selectedItem: any) => {
                  formik.setFieldValue('type', selectedItem.value)
                }}
                value={{
                  value:
                    (type.find((x: any) => x.value === formik.values.type) || {}).value || null,
                  label: (type.find((x: any) => x.value === formik.values.type) || {}).label || '',
                }}
              />
            </div>
            <div className='col-md-4 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.LANG'})}</label>
              <Select
                // classNamePrefix='react-select'
                styles={selectStyles}
                placeholder=''
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                className='react-select-styled react-select-solid'
                classNames={{
                  control: () =>
                    formik.touched.language && !formik.errors.language
                      ? 'form-select p-0 border-success'
                      : formik.touched.language && formik.errors.language
                      ? 'form-select p-0 border-danger'
                      : 'form-select p-0',
                }}
                options={language.map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
                onChange={(selectedItem: any) => {
                  formik.setFieldValue('language', selectedItem.value)
                }}
                value={{
                  value:
                    (language.find((x: any) => x.id === formik.values.language) || {}).id || null,
                  label:
                    (language.find((x: any) => x.id === formik.values.language) || {}).name || '',
                }}
              />
            </div>
            <div className='col-md-4 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.REGION'})}</label>
              <Select
                // classNamePrefix='react-select'
                styles={selectStyles}
                placeholder=''
                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                className='react-select-styled react-select-solid'
                classNames={{
                  control: () =>
                    formik.touched.region_id && !formik.errors.region_id
                      ? 'form-select p-0 border-success'
                      : formik.touched.region_id && formik.errors.region_id
                      ? 'form-select p-0 border-danger'
                      : 'form-select p-0',
                }}
                options={[
                  ...props.cities,
                  {id: 0, name: intl.formatMessage({id: 'COMMON.EVERYONE'})},
                ].map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
                onChange={(selectedItem: any) => {
                  formik.setFieldValue('region_id', selectedItem.value)
                }}
                value={{
                  value:
                    (
                      [
                        ...props.cities,
                        {id: 0, name: intl.formatMessage({id: 'COMMON.EVERYONE'})},
                      ].find((x: any) => x.id === formik.values.region_id) || {}
                    ).id || null,
                  label:
                    (
                      [
                        ...props.cities,
                        {id: 0, name: intl.formatMessage({id: 'COMMON.EVERYONE'})},
                      ].find((x: any) => x.id === formik.values.region_id) || {}
                    ).name || '',
                }}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <div>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.SEND_DATE'})}</label>
              </div>

              <DatePicker
                selected={(formik.values.send_date && new Date(formik.values.send_date)) || null}
                onChange={(val: any) => {
                  formik.setFieldValue('send_date', val)
                }}
                dateFormat='dd.MM.yyyy HH:mm'
                timeFormat='HH:mm'
                timeIntervals={15}
                minDate={new Date()}
                showTimeSelect
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.send_date && formik.errors.send_date},
                  {
                    'is-valid': formik.touched.send_date && !formik.errors.send_date,
                  }
                )}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'COMMON.TEXT'})}</label>
              <textarea
                {...formik.getFieldProps('text')}
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.text && formik.errors.text},
                  {
                    'is-valid': formik.touched.text && !formik.errors.text,
                  }
                )}
                value={formik.values.text}
                rows={8}
              />
            </div>
            {formik.values?.type !== 'text' && (
              <div className='col-md-12 mb-3'>
                <label className='form-label'>{intl.formatMessage({id: 'COMMON.FILE'})}</label>
                <Dropzone
                  multiple={false}
                  onDrop={(files) => formik.setFieldValue('media_file', files[0])}
                >
                  {({getRootProps, getInputProps}) => (
                    <div
                      {...getRootProps({
                        className: clsx(
                          'form-control c-dropzone',
                          {
                            'is-invalid': formik.touched.media_file && formik.errors.media_file,
                          },
                          {
                            'is-valid': formik.touched.media_file && !formik.errors.media_file,
                          }
                        ),
                      })}
                    >
                      <input {...getInputProps()} />
                      <p className='m-0'>
                        {!!formik.values.media_file
                          ? intl.formatMessage({id: 'COMMON.PICTURE_SELECTED'})
                          : intl.formatMessage({id: 'COMMON.SELECT_PICTURE'})}
                      </p>
                    </div>
                  )}
                </Dropzone>
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          {(props.item?.status || 0) === 0 && (
            <button
              type='button'
              className='btn btn-danger me-auto btn-sm'
              // @ts-ignore
              onClick={() => (handleClose(), deleteModalRef.current.showModal())}
            >
              {intl.formatMessage({id: 'COMMON.DELETE'})}
            </button>
          )}

          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </button>

          {(props.item?.status || 0) === 0 && (
            <button
              type='button'
              className='btn btn-success'
              onClick={() => {
                formik.handleSubmit()
              }}
              disabled={loading}
              data-kt-indicator={loading === true ? 'on' : 'off'}
            >
              <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>

              <span className='indicator-progress'>
                {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <DeleteModal item={props.item} ref={deleteModalRef} refreshData={props.refreshData} />
    </>
  )
})
