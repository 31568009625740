import clsx from 'clsx'
import {forwardRef, useImperativeHandle, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

import {MAIN_STATUSES, swalDefaultConfig} from '../../../../_metronic/helpers/constants'
import {swal} from '../../../../_metronic/helpers/swal'
import axiosInstance from '../../../utils/axiosInstance'

export const AddModal = forwardRef((props: any, ref: any) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    formik.resetForm()
    setShow(true)
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow()
    },
  }))

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    description: Yup.string(),
    key: Yup.string()
      .required()
      .min(3, intl.formatMessage({id: 'VALIDATION.MIN_SYMBOL'}, {number: 3})),
    isActive: Yup.number().integer(),
  })

  const initialValues = {
    title: '',
    key: '',
    description: '',
    isActive: 1,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)

      try {
        const res = await axiosInstance.post(
          '/roles/',
          {
            title: values.title,
            key: values.key,
            description: values.description,
            // @ts-ignore:
            is_active: !!Number(values.isActive),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        swal.fire(
          swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.SUCCESS_ADD'}), 'success')
        )
      } catch (err: any) {
        if (err.response.status == 400) {
          swal.fire(
            swalDefaultConfig(intl.formatMessage({id: 'NOTIFICATION.BAD_REQUEST'}), 'error')
          )
        } else {
          swal.fire(
            swalDefaultConfig(
              intl.formatMessage({id: 'NOTIFICATION.INTERNAL_SERVER_ERROR'}),
              'error'
            )
          )
        }
        // let msg = (((err || {}).response || {}).data || {}).message

        // setStatus(intl.formatMessage({id: 'VALIDATION.API_ERROR'}, {text: msg || err.message}))
        setSubmitting(false)
        setLoading(false)
        return
      }
      setShow(false)
      setSubmitting(false)
      setLoading(false)
      props.refreshData()
    },
  })

  const getSelectContent = (
    arr: any,
    keyName = '',
    valueKey = '',
    valueName: string | null = null,
    isProper = true
  ) => {
    if (!arr || !arr.length) return []

    let content = []

    for (const [i, value] of arr.entries()) {
      content.push(
        // <option key={keyName + i} value={value[valueKey]}>
        //   {valueName && value[valueName]}
        //   {!valueName && isProper && value.nameRu}
        //   {!valueName && !isProper && value.name}
        // </option>
        <label
          key={keyName + i}
          className={clsx('btn btn-outline btn-color-muted p-2', {
            active: formik.values.isActive == value.id,
            [value.class]: true,
          })}
          data-kt-button='true'
        >
          <input
            className='btn-check'
            type='radio'
            name='RadioStatus'
            value={value.id}
            checked={formik.values.isActive == value.id}
            onChange={(e) => formik.setFieldValue('isActive', e.currentTarget.value)}
          />
          {valueName && value[valueName]}
          {!valueName && isProper && value.nameRu}
          {!valueName && !isProper && value.name}
        </label>
      )
    }

    return content
  }

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header>
        <h3 className='modal-title'>{intl.formatMessage({id: 'COMMON.ADD'})}</h3>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className='row' onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.NAME'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('title')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              value={formik.values.title}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.KEY'})}</label>
            <input
              type='text'
              {...formik.getFieldProps('key')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.key && formik.errors.key},
                {
                  'is-valid': formik.touched.key && !formik.errors.key,
                }
              )}
              value={formik.values.key}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.DESCRIPTION'})}</label>
            <textarea
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              value={formik.values.description}
              // placeholder='Leave a comment here'
              rows={4}
            />
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'COMMON.STATUS'})}</label>
            <div className='form-check form-check-custom form-check-solid'>
              <div
                className='btn-group w-100'
                data-kt-buttons='true'
                data-kt-buttons-target='[data-kt-button]'
              >
                {getSelectContent(MAIN_STATUSES, 'patientStatus-')}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </button>
        <button
          type='button'
          className='btn btn-success'
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={loading}
          data-kt-indicator={loading == true ? 'on' : 'off'}
        >
          <span className='indicator-label'>{intl.formatMessage({id: 'COMMON.SAVE'})}</span>
          <span className='indicator-progress'>
            {intl.formatMessage({id: 'COMMON.PLS_WAIT'})}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>{' '}
        </button>
      </Modal.Footer>
    </Modal>
  )
})
