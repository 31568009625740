/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {useAuth} from '../../../../app/modules/auth/useAuth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {hasAccess, hasAccessByArray} = useAuth()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {hasAccessByArray([
        'statistics-location',
        'statistics-order-type',
        'statistics-payment',
        'statistics-products',
        'statistics-system',
        'statistics-terminals',
        'statistics-users',
        'statistics-courier',
        'statistics-abc',
        'statistics-xyz',
        'statistics-abcxyz',
        'statistics-rfm',
      ]) && (
        <AsideMenuItemWithSub
          to='/statistics'
          title={intl.formatMessage({id: 'MENU.STATS'})}
          icon='/media/icons/duotune/graphs/gra004.svg'
        >
          {hasAccess('statistics-abc') && (
            <AsideMenuItem
              to='/statistics/abc'
              title={intl.formatMessage({id: 'MENU.ABC'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-abc') && (
            <AsideMenuItem
              to='/statistics/abc-clients'
              title={intl.formatMessage({id: 'MENU.ABC_CLIENTS'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-xyz') && (
            <AsideMenuItem
              to='/statistics/xyz'
              title={intl.formatMessage({id: 'MENU.XYZ'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-abcxyz') && (
            <AsideMenuItem
              to='/statistics/abcxyz'
              title={intl.formatMessage({id: 'MENU.ABCXYZ'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-rfm') && (
            <AsideMenuItem
              to='/statistics/rfm'
              title={intl.formatMessage({id: 'MENU.RFM'})}
              hasBullet={true}
            />
          )}
          {/* {hasAccess('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.CLIENTS'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.TOP10'})}
              hasBullet={true}
            />
          )} */}

          {hasAccess('statistics-order-type') && (
            <AsideMenuItem
              to='/statistics/order-type'
              title={intl.formatMessage({id: 'MENU.ORDER_TYPE'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-courier') && (
            <AsideMenuItem
              to='/statistics/couriers'
              title={intl.formatMessage({id: 'MENU.COURIERS'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-terminals') && (
            <AsideMenuItem
              to='/statistics/terminals'
              title={intl.formatMessage({id: 'MENU.BRANCHES'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-payment-type') && (
            <AsideMenuItem
              to='/statistics/payment-type'
              title={intl.formatMessage({id: 'MENU.PAYMENT_TYPE'})}
              hasBullet={true}
            />
          )}
          {/* {hasAccess('statistics-location') && (
            <AsideMenuItem
              to='/statistics/location'
              title={intl.formatMessage({id: 'MENU.LOCATION'})}
              hasBullet={true}
            />
          )} */}
          {hasAccess('statistics-products') && (
            <AsideMenuItem
              to='/statistics/products'
              title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
              hasBullet={true}
            />
          )}
          {hasAccess('statistics-system') && (
            <AsideMenuItem
              to='/statistics/system'
              title={intl.formatMessage({id: 'MENU.SYSTEM'})}
              hasBullet={true}
            />
          )}

          {hasAccess('statistics-users') && (
            <AsideMenuItem
              to='/statistics/users'
              title={intl.formatMessage({id: 'COMMON.OPERATORS'})}
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      )}

      {hasAccessByArray(['orders-view', 'orders-add', 'couriers-map-view']) && (
        <AsideMenuItemWithSub
          to='/orders'
          title={intl.formatMessage({id: 'MENU.ORDERS'})}
          icon='/media/icons/duotune/files/fil001.svg'
        >
          {hasAccess('orders-add') && (
            <AsideMenuItem
              to='/orders/create-order'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.ORDERS_NEW'})}
            />
          )}
          {hasAccess('orders-view') && (
            <>
              <AsideMenuItem
                to='/orders'
                hasBullet={true}
                title={intl.formatMessage({id: 'MENU.ORDERS_LIST'})}
              />
            </>
          )}
        </AsideMenuItemWithSub>
      )}

      {hasAccessByArray(['heat-map-view', 'couriers-map-view', 'couriers-map-view']) && (
        <AsideMenuItemWithSub
          to='/delivery'
          title={intl.formatMessage({id: 'MENU.DELIVERY'})}
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
        >
          {hasAccess('heat-map-view') && (
            <AsideMenuItem
              to='/heat-map'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.HEAT_MAP'})}
            />
          )}

          {hasAccess('couriers-map-view') && (
            <AsideMenuItem
              to='/couriers-map'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.COURIERS_MAP'})}
            />
          )}
          {hasAccess('couriers-map-view') && (
            <AsideMenuItem
              to='/couriers'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.COURIERS'})}
            />
          )}
        </AsideMenuItemWithSub>
      )}

      {hasAccess('accounts-view') && (
        <AsideMenuItem
          to='/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.CLIENTS'})}
        />
      )}
      {hasAccess('feedbacks-view') && (
        <AsideMenuItem
          to='/feedbacks'
          icon='/media/icons/duotune/general/gen011.svg'
          fontIcon='bi-layers'
          title={intl.formatMessage({id: 'MENU.FEEDBACKS'})}
        />
      )}

      {hasAccessByArray([
        'groups-show',
        'notifications-view',
        'slider-view',
        'gallery-view',
        'news-view',
        'pages-view',
        'restaurants-view',
      ]) && (
        <AsideMenuItemWithSub
          to='/pages'
          title={intl.formatMessage({id: 'MENU.CONTENT'})}
          icon='/media/icons/duotune/layouts/lay008.svg'
        >
          {hasAccess('groups-show') && (
            <AsideMenuItem
              to='/groups'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.MENU'})}
            />
          )}
          {hasAccess('notifications-view') && (
            <AsideMenuItem
              to='/notifications'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.NOTIFICATIONS'})}
            />
          )}
          {hasAccess('slider-view') && (
            <AsideMenuItem
              to='/slider'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.SLIDER'})}
            />
          )}
          {/* {hasAccess('gallery-view') && (
        <AsideMenuItem
          to='/gallery'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.GALLERY'})}
        />
      )} */}
          {/* {hasAccess('news-view') && (
        <AsideMenuItem
          to='/news'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.NEWS'})}
        />
      )} */}
          {hasAccess('pages-view') && (
            <AsideMenuItem
              to='/pages'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.PAGES'})}
            />
          )}
          {/* {hasAccess('restaurants-view') && (
        <AsideMenuItem
          to='/restaurants'
          hasBullet={true}
          title={intl.formatMessage({id: 'MENU.RESTAURANTS'})}
        />
      )} */}
        </AsideMenuItemWithSub>
      )}

      {hasAccessByArray([
        'organizations-view',
        'branches-view',
        'users-view',
        'roles-view',
        'settings-view',
      ]) && (
        <AsideMenuItemWithSub
          to='/settings'
          title={intl.formatMessage({id: 'MENU.SETTINGS'})}
          icon='/media/icons/duotune/coding/cod001.svg'
        >
          {hasAccess('organizations-view') && (
            <AsideMenuItem
              to='/organizations'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.ORGANIZATION'})}
            />
          )}
          {hasAccess('branches-view') && (
            <AsideMenuItem
              to='/branches'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.BRANCHES'})}
            />
          )}
          {hasAccess('branches-view') && (
            <AsideMenuItem
              to='/payment-types'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.PAYMENT_TYPES'})}
            />
          )}
          {hasAccess('users-view') && (
            <AsideMenuItem
              to='/users'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.USERS'})}
            />
          )}
          {hasAccess('roles-view') && (
            <AsideMenuItem
              to='/roles'
              title={intl.formatMessage({id: 'MENU.ROLES'})}
              hasBullet={true}
            />
          )}
          {hasAccess('settings-view') && (
            <AsideMenuItem
              to='/settings'
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.SETTINGS'})}
            />
          )}
        </AsideMenuItemWithSub>
      )}
    </>
  )
}
