import 'bootstrap-daterangepicker/daterangepicker.css'
import React, {useRef, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import moment from 'moment'

import Select from 'react-select'

import ReactPaginate from 'react-paginate'

// COMPONENTS
import {KTSVG} from '../../../_metronic/helpers'
import FooterCopyright from '../../modules/FooterCopyright'

// HELPERS
import {daysOfWeek, monthNames} from '../../../_metronic/helpers/constants'
import {parseAsInteger, parseAsString, useQueryState} from 'nuqs'
import {useQuery} from 'react-query'
import clsx from 'clsx'
import {dateTimeFormat, getPriceFormatted} from '../../utils/helpers'
import {SYSTEMS} from '../../utils/constants'
import LoadingDataComponent from '../../components/LoadingDataComponent'
import FormErrorComponent from '../../components/FormErrorComponent'
import {useAuth} from '../../modules/auth/useAuth'
import axiosInstance from '../../utils/axiosInstance'

export function OrdersIndex() {
  const intl = useIntl()
  const {classes} = useLayout()
  const {hasAccess} = useAuth()

  let navigate = useNavigate()
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const [selectedItems, setSelectedItems] = useState<any>([])
  const [rowsCount, setRowsCount] = useState(0)

  const [page, setPage] = useQueryState(
    'page',
    parseAsInteger.withDefault(1).withOptions({history: 'push'})
  )
  const [pageSize, setPageSize] = useQueryState(
    'pageSize',
    parseAsInteger.withDefault(50).withOptions({history: 'push'})
  )
  const [search, setSearch] = useQueryState(
    'search',
    parseAsString.withDefault('').withOptions({history: 'push'})
  )
  const [dateFrom, setDateFrom] = useQueryState(
    'dateFrom',
    parseAsString.withOptions({history: 'push'})
  )
  const [dateTo, setDateTo] = useQueryState('dateTo', parseAsString.withOptions({history: 'push'}))

  const {data, isLoading, error} = useQuery({
    queryKey: ['orders', page, pageSize, dateFrom, dateTo, search],
    queryFn: getData,
    keepPreviousData: true,
  })

  async function getData() {
    const res = await axiosInstance.get(`orders/`, {
      params: {
        page,
        page_size: pageSize,
        from: dateFrom,
        to: dateTo,
        multi_search: search,
      },
    })
    setRowsCount(res.data.count)
    return res.data.data
  }

  const handleDateRangePickerCallback = async (start: any, end: any, label: any) => {
    await setDateFrom(start.format('YYYY-MM-DD'))
    await setDateTo(end.format('YYYY-MM-DD'))
  }

  const handlePaginationClick = async (event: any) => {
    await setPage(event.selected + 1)
  }

  const getBreadcrumbs = () => {
    let arr = [
      {
        title: intl.formatMessage({id: 'MENU.MAIN'}),
        path: '/',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return arr
  }

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumbs()}>
        {intl.formatMessage({id: 'MENU.ORDERS'})}
      </PageTitle>
      <div className='h-100 d-flex flex-column flex-column-fluid'>
        <div className='card card-stretch shadow mb-2'>
          <div className='card-header'>
            <div className='d-flex align-items-center justify-content-center'>
              {hasAccess('orders-add') && (
                <button
                  className='btn btn-success me-3'
                  data-bs-toggle='modal' // @ts-ignore
                  onClick={() => navigate(`/orders/create-order`)}
                >
                  {intl.formatMessage({id: 'COMMON.ADD'})}
                </button>
              )}

              <div className='d-flex align-items-center position-relative input-group-sm my-1 me-3'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-4'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                {search.length > 0 && (
                  <button
                    className='btn link btn-sm'
                    data-bs-toggle='modal'
                    onClick={(e) => setSearch('')}
                  >
                    <i className='fa fa-times fs-5'></i>
                  </button>
                )}
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='me-3 input-group-sm position-relative es-datepicker-wrapper'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(),
                    endDate: moment(),
                    ranges: {
                      [intl.formatMessage({id: 'COMMON.TODAY'})]: [moment(), moment()],
                      [intl.formatMessage({id: 'COMMON.YESTERDAY'})]: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_7_DAYS'})]: [
                        moment().subtract(6, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_30_DAYS'})]: [
                        moment().subtract(29, 'days'),
                        moment(),
                      ],
                      [intl.formatMessage({id: 'COMMON.THIS_MONTH'})]: [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      [intl.formatMessage({id: 'COMMON.LAST_MONTH'})]: [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    },
                    locale: {
                      format: 'DD/MM/YYYY',
                      separator: ' - ',
                      applyLabel: 'Подтвердить',
                      cancelLabel: 'Отменить',
                      fromLabel: 'From',
                      toLabel: 'To',
                      customRangeLabel: 'Пользовательский',
                      daysOfWeek: daysOfWeek,
                      monthNames: monthNames,
                      firstDay: 1,
                    },
                  }}
                  onCallback={handleDateRangePickerCallback}
                >
                  <input type='text' className='form-control' />
                </DateRangePicker>
              </div>
              <div className='ms-3'>
                <button
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-funnel fs-5'></i>
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                  data-kt-menu='true'
                >
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark er'>
                      {intl.formatMessage({id: 'COMMON.FILTER'})}
                    </div>
                  </div>

                  <div className='separator border-gray-200'></div>

                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <div className='mb-5'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'COMMON.STATUS'})}
                        </label>
                        <Select
                          classNamePrefix='react-select'
                          styles={{
                            menu: (provided) => ({...provided, zIndex: 5}),
                          }}
                          placeholder=''
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          className='react-select-styled react-select-solid'
                          // options={filteredStatuses(statuses)}
                          // onChange={(e: any) => {
                          //   setSearchParams({...getQueryObject(), status: e.value})
                          // }}
                          // value={{
                          //   value: (statuses.find((x: any) => x.id == status) || {}).code || null,
                          //   label:
                          //     (statuses.find((x: any) => x.id == status) || {}).description_ru ||
                          //     '',
                          // }}
                        />
                      </div>
                      <div className='mb-5'>
                        <label className='form-label'>
                          {intl.formatMessage({id: 'MENU.BRANCHES'})}
                        </label>
                        <Select
                          classNamePrefix='react-select'
                          styles={{
                            menu: (provided) => ({...provided, zIndex: 5}),
                          }}
                          placeholder=''
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          className='react-select-styled react-select-solid'
                          // options={filteredBranches(branches)}
                          // onChange={(e: any) => {
                          //   setSearchParams({...getQueryObject(), terminal_id: e.value})
                          // }}
                          // value={{
                          //   value:
                          //     (branches.find((x: any) => x.id == terminal_id) || {}).id || null,
                          //   label:
                          //     (branches.find((x: any) => x.id == terminal_id) || {}).name_ru || '',
                          // }}
                        />
                      </div>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        // onClick={clearSearchParams}
                      >
                        {intl.formatMessage({id: 'COMMON.CLEAR'})}
                      </button>

                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        {intl.formatMessage({id: 'COMMON.CLOSE'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-stretch shadow mb-2 flex-column-fluid'>
          <div className='card-body main-overflow-x' style={{height: windowSize.current[1] - 320}}>
            <FormErrorComponent error={error ? error.toString() : null} />
            <LoadingDataComponent show={isLoading} />
            {!isLoading ? (
              <div className='table-responsive'>
                <table className='table table-hover border table-rounded table-row-dashed table-row-gray-300 gy-2 gs-1'>
                  <thead>
                    <tr className='bg-light'>
                      <th className='w-25px ps-2'></th>
                      <th>{intl.formatMessage({id: 'COMMON.ORDER_NUMBER'})}</th>
                      <th>{intl.formatMessage({id: 'COMMON.CHEQUE_NUMBER'})}</th>
                      <th>{intl.formatMessage({id: 'COMMON.BRANCH'})}</th>
                      <th>{intl.formatMessage({id: 'COMMON.CLIENT'})}</th>
                      <th>{intl.formatMessage({id: 'COMMON.PHONE'})}</th>
                      <th className='text-center'>{intl.formatMessage({id: 'COMMON.DATE'})}</th>
                      <th className='text-center'>
                        {intl.formatMessage({id: 'COMMON.PAYMENT_TYPE'})}
                      </th>
                      <th className='text-nowrap text-end'>
                        {intl.formatMessage({id: 'COMMON.TOTAL'})}
                      </th>
                      <th className='text-center'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                      <th>{intl.formatMessage({id: 'COMMON.COURIER'})}</th>
                      <th className='text-center'>{intl.formatMessage({id: 'COMMON.SYSTEM'})}</th>
                      <th>Оператор</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr
                        key={'order-' + item.id}
                        className={clsx(
                          'c-pointer bg-opacity-25',
                          {'bg-danger': item.status === 9},
                          {
                            'bg-success': item.status === 8,
                          },
                          {
                            'bg-warning': item.status < 8 && item.status > 0,
                          }
                        )}
                        onClick={() => {
                          if (!hasAccess('orders-edit')) return
                          // if (!!item.has_child) {
                          //   setSearchParams({parent_id: item.id})
                          //   return
                          // }
                          // navigate(`/orders/edit-order?orderId=${item.id}`)
                          navigate(`/orders/view/${item.id}`)
                          return
                        }}
                      >
                        <td className='ps-3'>
                          {item.is_self_service ? (
                            <i className='fa fa-user text-primary'></i>
                          ) : (
                            <i className='fa fa-car text-danger'></i>
                          )}
                        </td>
                        <td>{item.id}</td>
                        <td>{item.number}</td>
                        <td>{(item.terminal || {}).name_ru}</td>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td className='text-center'>{dateTimeFormat(item.created_at)}</td>
                        <td className='text-center'>{(item.payment || {}).name_ru || '-'}</td>
                        <td className='text-nowrap text-end'>
                          {getPriceFormatted(parseInt(item.full_sum))}
                        </td>
                        <td className='text-center'>{(item.statuses || {}).description_ru}</td>
                        <td>
                          {item.courier?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                        <td className='text-center'>{SYSTEMS[item.system]}</td>
                        <td>
                          {item.created?.name || intl.formatMessage({id: 'COMMON.NOT_DEFINED'})}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* FOOTER */}
        <div className='card card-stretch shadow py-4 d-flex flex-lg-column' id='kt_footer'>
          <div
            className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            <div className='order-1 d-flex align-items-center mb-2 mb-md-0'>
              <div className='dataTables_length' id='kt_ecommerce_report_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_report_sales_table_length'
                    aria-controls='kt_ecommerce_report_sales_table'
                    className='form-select form-select-sm form-select-solid'
                    value={pageSize}
                    onChange={(e) => setPageSize(parseInt(e.target.value))}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div>

              {rowsCount > 1 ? (
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  previousLabel='<'
                  onPageChange={handlePaginationClick}
                  initialPage={page - 1}
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(rowsCount / pageSize)}
                  renderOnZeroPageCount={null}
                  containerClassName='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  activeClassName='active'
                  previousClassName='page-item previous'
                  previousLinkClassName='page-link'
                  nextClassName='page-item next'
                  nextLinkClassName='page-link'
                  disabledClassName='disabled'
                  marginPagesDisplayed={0}
                />
              ) : (
                ''
              )}
            </div>

            {<FooterCopyright />}
          </div>
        </div>
      </div>
    </>
  )
}
