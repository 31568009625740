export const getMediaUrl = (folder: string, name: string) => {
  return process.env.REACT_APP_MEDIA_URL + folder + '/' + name
}

export const IsValidPhoneFormat = (phone: string) => {
  let clearPhone = clearPhoneNumber(phone)

  // return !!clearPhone.match(/^(\+998)?(998)?(\s)?\d{2}(\s)?\d{3}(\s)?\d{4}$/)
  return !!clearPhone.match(/^(998)(\s)?\d{2}(\s)?\d{3}(\s)?\d{4}$/)
}

export const convertToPrice = (number: number | string) =>
  (number + '').split(/(?!^)(?=(?:\d{3})+(?:\.|$))/).join(' ')

export const buildQueryData = (data: Object) => {
  let arr = []

  for (const [key, value] of Object.entries(data)) {
    arr.push(`${key}=${value}`)
  }

  return arr.join('&')
}

export const clearPhoneNumber = (phone: string) => phone.replace(/(\s|\+|\(|\)|-|_)/g, '')
